import { AuthUserProfile, PartnerPersona, personaConfig } from '../config';

export const getUserPersonaPartnerConfig = (
  authUserInfo: AuthUserProfile
): { partner: string | null; persona: PartnerPersona | null } => {
  const authUserPersonas = authUserInfo?.personas;
  if (!authUserPersonas || authUserPersonas.length < 1)
    return { partner: null, persona: null };

  const validPersonaKey = Object.keys(personaConfig)
    .filter((key) =>
      authUserPersonas.some(
        ({ sourcePlatform, entityType, externalId }) =>
          sourcePlatform === personaConfig[key].sourcePlatform &&
          entityType === personaConfig[key].entityType &&
          externalId === personaConfig[key].externalId
      )
    )
    .at(0);

  if (typeof validPersonaKey === 'undefined')
    return { partner: null, persona: null };
  else
    return {
      partner: validPersonaKey,
      persona: personaConfig[validPersonaKey],
    };
};

export const isPersonaAvailable = (authUserInfo: AuthUserProfile): boolean => {
  const partnerPersona = getUserPersonaPartnerConfig(authUserInfo);
  return !!partnerPersona;
};
