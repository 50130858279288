import { Partner } from '@3as-affiliates/shared/types-configs';
import styled from 'styled-components';
import AsurionAcrisure from './assets/asurion-acrisure.svg';
import Affiliates from './assets/asurion-affiliates.svg';
import AsurionComparion from './assets/asurion-comparion.svg';
import Asurion from './assets/asurion.svg';
import Comparion from './assets/comparion.svg';

const Logo = styled.img<{ height: string }>`
  height: ${({ height }) => height};
  width: auto;
`;

export type PartnerLogoProps = {
  partner?: Partner;
  coBrand?: boolean;
  logoHeight?: string;
};

const coBrandedLogos: Map<Partner, string> = new Map([
  [Partner.ACRISURE, AsurionAcrisure],
  [Partner.COMPARION, AsurionComparion],
]);

const partnerLogos: Map<Partner, string> = new Map([
  [Partner.AFFILIATES, Affiliates],
  [Partner.COMPARION, Comparion],
]);

export const PartnerLogo = (partnerLogoProps: PartnerLogoProps) => {
  let logo: string | undefined = Asurion;
  let logoName = 'Asurion';
  const logoHeight = partnerLogoProps.logoHeight
    ? partnerLogoProps.logoHeight
    : '30px';

  if (partnerLogoProps.partner && partnerLogoProps.coBrand) {
    logo = coBrandedLogos.get(partnerLogoProps.partner);
    logoName = partnerLogoProps.partner + ' cobranded';
  } else if (partnerLogoProps.partner) {
    logo = partnerLogos.get(partnerLogoProps.partner);
    logoName = partnerLogoProps.partner;
  }

  return <Logo src={logo} alt={`${logoName} logo`} height={logoHeight} />;
};
