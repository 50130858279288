import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCmsComponentLinkSkeleton } from './TypeCmsComponentLink';
import type { TypeUtilityComponentVerticalSpacingSkeleton } from './TypeUtilityComponentVerticalSpacing';

export interface TypeMarketingComponentFeatureFields {
  classes?: EntryFieldTypes.Symbol;
  variant: EntryFieldTypes.Symbol<
    'FeatureVariantVimeoLeftTextRight' | 'FeatureVariantYoutubeLeftTextRight'
  >;
  headline: EntryFieldTypes.Symbol;
  subheading: EntryFieldTypes.Text;
  list?: EntryFieldTypes.Array<EntryFieldTypes.Symbol>;
  cta?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCmsComponentLinkSkeleton>
  >;
  youtubeId?: EntryFieldTypes.Symbol;
  vimeoUrl?: EntryFieldTypes.Symbol;
  autoplay?: EntryFieldTypes.Boolean;
  poster?: EntryFieldTypes.AssetLink;
  topVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
  bottomVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
}

export type TypeMarketingComponentFeatureSkeleton = EntrySkeletonType<
  TypeMarketingComponentFeatureFields,
  'marketingComponentFeature'
>;
export type TypeMarketingComponentFeature<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingComponentFeatureSkeleton, Modifiers, Locales>;

export function isTypeMarketingComponentFeature<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingComponentFeature<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingComponentFeature';
}
