import type { Entry, EntrySkeletonType } from 'contentful';
import {
  TypeCmsComponentCardSkeleton,
  TypeCmsComponentResponsiveImageSkeleton,
  TypeMarketingComponentBasicComponentsSkeleton,
  TypeMarketingComponentFeatureSkeleton,
  TypeMarketingComponentHeroSkeleton,
  TypeMarketingComponentLogosSkeleton,
  TypeMarketingPageSkeleton,
  TypeUtilityComponentVerticalSpacingSkeleton,
} from './contentful';

export * from './contentful';

export type Contentful<T extends EntrySkeletonType> = Entry<
  T,
  'WITHOUT_UNRESOLVABLE_LINKS',
  string
>;

// Page Type
export type ContentfulMarketingPage =
  | Contentful<TypeMarketingPageSkeleton>
  | undefined;

// Marketing Components Types
export type ContentfulBasicComponent =
  Contentful<TypeMarketingComponentBasicComponentsSkeleton>;
export type ContentfulFeatureComponent =
  Contentful<TypeMarketingComponentFeatureSkeleton>;
export type ContentfulHeroComponent =
  Contentful<TypeMarketingComponentHeroSkeleton>;
export type ContentfulLogosComponent =
  Contentful<TypeMarketingComponentLogosSkeleton>;

// CMS Component Types
export type ContentfulCardComponent = Contentful<TypeCmsComponentCardSkeleton>;

// Utility Component Types
export type ContentfulVerticalSpacingComponent =
  Contentful<TypeUtilityComponentVerticalSpacingSkeleton>;
export type ContentfulResponsiveImageComponent =
  Contentful<TypeCmsComponentResponsiveImageSkeleton>;

export type ContentfulSpacing = {
  topVerticalSpacing?: ContentfulVerticalSpacingComponent;
  bottomVerticalSpacing?: ContentfulVerticalSpacingComponent;
};
