export enum AppPlatforms {
  MobileApp = 'MobileApp',
  Web = 'Web',
}

export enum SupportedPartners {
  Acrisure = 'Acrisure',
  Asurion = 'Asurion',
  Comparion = 'Comparion',
}

export enum SupportedProducts {
  HomePlus = 'HomePlus',
  ApplianceProtect = 'ApplianceProtect',
  AppliancePlus = 'AppliancePlus',
}

export const ProductSlugs: { [key: string]: string } = {
  [SupportedProducts.HomePlus]: 'home-plus',
  [SupportedProducts.ApplianceProtect]: 'appliance-protect',
  [SupportedProducts.AppliancePlus]: 'appliance-plus',
};

export const ProductSlugsInvertedKeys = Object.fromEntries(
  Object.entries(ProductSlugs).map((a) => a.reverse())
);

// Pattern: [Organization][Program][type]
// Note: AppliancesPlusTechniciansAffiliateServices is a legacy name for AsurionAppliancesPlusISP and is used in chat API
export enum ActiveCampaigns {
  AppliancesPlusTechniciansAffiliateServices = 'appliancesPlusTechniciansAffiliateServices',
  AsurionAppliancesPlusISP = 'AsurionAppliancesPlusISP',
  ComparionHomePlus = 'ComparionHomePlus',
}

export const OrganizationIds = {
  [SupportedPartners.Acrisure]: 'a1be629e-78e6-4548-ae9f-2740a7a4f188',
  [SupportedPartners.Asurion]: '63509088-8049-4808-88e0-d8bd988201a3',
  [SupportedPartners.Comparion]: '6124990d-ad7f-4021-a0bc-50c982e4aee3',
};

export const OrganizationInvertedKeys = Object.fromEntries(
  Object.entries(OrganizationIds).map((a) => a.reverse())
);

export enum CampaignIds {
  AcrisureDefaultCampaign = '5a6aec8f-a4d5-4508-a631-655625c65f57',
  AsurionAppliancesPlusISP = '392fed54-8086-4b72-9353-effbe1425b66',
  ComparionHomePlus = '71aa300e-5ea2-4f0d-9fc0-ac9cfb013ed0',
}

export enum CohortIds {
  AcrisureDefaultCohortId = 'cc44d738-de9c-4678-8ffe-0f32a4854ac3',
  AsurionAppliancesPlusISPDefault = 'ce1e2622-8ea9-4c47-a493-a84c4646299a',
  ComparionHomePlusDefault = 'b816d40b-722d-4827-ab7c-eedd7c8cb33d',
}
