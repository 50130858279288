import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCmsComponentResponsiveImageSkeleton } from './TypeCmsComponentResponsiveImage';
import type { TypeUtilityComponentVerticalSpacingSkeleton } from './TypeUtilityComponentVerticalSpacing';

export interface TypeMarketingComponentLogosFields {
  classes?: EntryFieldTypes.Symbol;
  variant: EntryFieldTypes.Symbol<
    'LogosLarge' | 'LogosLargeFlexCenter' | 'LogosRatings' | 'LogosSmall'
  >;
  text?: EntryFieldTypes.Symbol;
  images: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCmsComponentResponsiveImageSkeleton>
  >;
  topVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
  bottomVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
}

export type TypeMarketingComponentLogosSkeleton = EntrySkeletonType<
  TypeMarketingComponentLogosFields,
  'marketingComponentLogos'
>;
export type TypeMarketingComponentLogos<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingComponentLogosSkeleton, Modifiers, Locales>;

export function isTypeMarketingComponentLogos<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingComponentLogos<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingComponentLogos';
}
