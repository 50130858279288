import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeCmsComponentResponsiveImageFields {
  altText: EntryFieldTypes.Symbol;
  label?: EntryFieldTypes.Symbol;
  default: EntryFieldTypes.AssetLink;
  md?: EntryFieldTypes.AssetLink;
  md2?: EntryFieldTypes.AssetLink;
  lg?: EntryFieldTypes.AssetLink;
  xl?: EntryFieldTypes.AssetLink;
  xxl?: EntryFieldTypes.AssetLink;
}

export type TypeCmsComponentResponsiveImageSkeleton = EntrySkeletonType<
  TypeCmsComponentResponsiveImageFields,
  'cmsComponentResponsiveImage'
>;
export type TypeCmsComponentResponsiveImage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeCmsComponentResponsiveImageSkeleton, Modifiers, Locales>;

export function isTypeCmsComponentResponsiveImage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCmsComponentResponsiveImage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'cmsComponentResponsiveImage';
}
