import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCmsComponentLinkSkeleton } from './TypeCmsComponentLink';
import type { TypeCmsComponentResponsiveImageSkeleton } from './TypeCmsComponentResponsiveImage';

export interface TypeCmsComponentCardFields {
  headline?: EntryFieldTypes.Symbol;
  headingLevel: EntryFieldTypes.Symbol<
    'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p'
  >;
  subheading?: EntryFieldTypes.Text;
  cta?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCmsComponentLinkSkeleton>
  >;
  image?: EntryFieldTypes.EntryLink<TypeCmsComponentResponsiveImageSkeleton>;
}

export type TypeCmsComponentCardSkeleton = EntrySkeletonType<
  TypeCmsComponentCardFields,
  'cmsComponentCard'
>;
export type TypeCmsComponentCard<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeCmsComponentCardSkeleton, Modifiers, Locales>;

export function isTypeCmsComponentCard<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCmsComponentCard<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'cmsComponentCard';
}
