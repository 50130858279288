import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';

export interface TypeCmsComponentLinkFields {
  text: EntryFieldTypes.Symbol;
  url?: EntryFieldTypes.Symbol;
  size?: EntryFieldTypes.Symbol<'large' | 'medium' | 'small'>;
  variant?: EntryFieldTypes.Symbol<'default' | 'flat' | 'outline' | 'text'>;
}

export type TypeCmsComponentLinkSkeleton = EntrySkeletonType<
  TypeCmsComponentLinkFields,
  'cmsComponentLink'
>;
export type TypeCmsComponentLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeCmsComponentLinkSkeleton, Modifiers, Locales>;

export function isTypeCmsComponentLink<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeCmsComponentLink<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'cmsComponentLink';
}
