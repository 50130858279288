import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeMarketingComponentBasicComponentsSkeleton } from './TypeMarketingComponentBasicComponents';
import type { TypeMarketingComponentFeatureSkeleton } from './TypeMarketingComponentFeature';
import type { TypeMarketingComponentHeroSkeleton } from './TypeMarketingComponentHero';
import type { TypeMarketingComponentLogosSkeleton } from './TypeMarketingComponentLogos';
import type { TypeUtilityComponentVerticalSpacingSkeleton } from './TypeUtilityComponentVerticalSpacing';

export interface TypeMarketingPageFields {
  previewUri: EntryFieldTypes.Symbol;
  pageTitle: EntryFieldTypes.Symbol;
  sections: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<
      | TypeMarketingComponentBasicComponentsSkeleton
      | TypeMarketingComponentFeatureSkeleton
      | TypeMarketingComponentHeroSkeleton
      | TypeMarketingComponentLogosSkeleton
      | TypeUtilityComponentVerticalSpacingSkeleton
    >
  >;
}

export type TypeMarketingPageSkeleton = EntrySkeletonType<
  TypeMarketingPageFields,
  'marketingPage'
>;
export type TypeMarketingPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingPageSkeleton, Modifiers, Locales>;

export function isTypeMarketingPage<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingPage<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingPage';
}
