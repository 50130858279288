import type {
  ChainModifiers,
  Entry,
  EntryFieldTypes,
  EntrySkeletonType,
  LocaleCode,
} from 'contentful';
import type { TypeCmsComponentLinkSkeleton } from './TypeCmsComponentLink';
import type { TypeCmsComponentResponsiveImageSkeleton } from './TypeCmsComponentResponsiveImage';
import type { TypeUtilityComponentVerticalSpacingSkeleton } from './TypeUtilityComponentVerticalSpacing';

export interface TypeMarketingComponentHeroFields {
  classes?: EntryFieldTypes.Symbol;
  variant: EntryFieldTypes.Symbol<'HeroVariantTextLeftImageRight'>;
  headingLevel: EntryFieldTypes.Symbol<'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
  headline: EntryFieldTypes.Symbol;
  subheading: EntryFieldTypes.Text;
  cta?: EntryFieldTypes.Array<
    EntryFieldTypes.EntryLink<TypeCmsComponentLinkSkeleton>
  >;
  image: EntryFieldTypes.EntryLink<TypeCmsComponentResponsiveImageSkeleton>;
  topVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
  bottomVerticalSpacing?: EntryFieldTypes.EntryLink<TypeUtilityComponentVerticalSpacingSkeleton>;
}

export type TypeMarketingComponentHeroSkeleton = EntrySkeletonType<
  TypeMarketingComponentHeroFields,
  'marketingComponentHero'
>;
export type TypeMarketingComponentHero<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
> = Entry<TypeMarketingComponentHeroSkeleton, Modifiers, Locales>;

export function isTypeMarketingComponentHero<
  Modifiers extends ChainModifiers,
  Locales extends LocaleCode
>(
  entry: Entry<EntrySkeletonType, Modifiers, Locales>
): entry is TypeMarketingComponentHero<Modifiers, Locales> {
  return entry.sys.contentType.sys.id === 'marketingComponentHero';
}
