import { Log, UserManager } from 'oidc-client-ts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AuthenticationState,
  AuthProviderProps,
  MobileAppAuthResponseType,
} from './config';
import { AuthContext } from './context';

export const WebAppAuthProvider: React.FC<AuthProviderProps> = ({
  authOpenIdConfig,
  isProd,
  children,
}) => {
  console.log('WebAppAuthProvider');
  const [authState, setAuthState] = useState<AuthenticationState>(
    AuthenticationState.Uninitialized
  );

  if (!isProd) {
    Log.setLogger(console);
    Log.setLevel(4);
  }

  const openIdUserManager = useMemo(
    () => new UserManager(authOpenIdConfig),
    [authOpenIdConfig]
  );

  openIdUserManager.events.addUserLoaded(() => {
    if (authState !== AuthenticationState.LoggedIn) {
      setAuthState(AuthenticationState.LoggedIn);
    }
  });

  openIdUserManager.events.addUserUnloaded(() => {
    setAuthState(AuthenticationState.LoggedOut);
  });

  const getAuthUserInfo = useCallback(async () => {
    const user = await openIdUserManager.getUser();
    return {
      asurionId: (user?.profile?.asurion_id || '') as string,
      email: (user?.profile?.principal_identifier || '') as string,
      accessToken: (user?.access_token || '') as string,
      personas: (user?.profile?.personas || []) as [],
    };
  }, [openIdUserManager]);

  const logIn = useCallback(() => {
    setAuthState(AuthenticationState.InProgress);
    openIdUserManager.signinRedirect();
  }, [openIdUserManager]);

  const handleWebAppLoginCallback = useCallback(() => {
    openIdUserManager
      .signinRedirectCallback()
      .then((user) => {
        if (user) {
          setAuthState(AuthenticationState.LoggedIn);
        }
      })
      .catch((e) => {
        setAuthState(AuthenticationState.LoggedOut);
      });
  }, [openIdUserManager]);

  const logOut = useCallback(() => {
    openIdUserManager.signoutRedirect();
  }, [openIdUserManager]);

  useEffect(() => {
    openIdUserManager.getUser().then((user) => {
      if (user) {
        if (user.refresh_token) {
          openIdUserManager
            .signinSilent()
            .then(() => {
              setAuthState(AuthenticationState.LoggedIn);
            })
            .catch((e) => {
              setAuthState(AuthenticationState.LoggedOut);
              logOut();
            });
        } else if (!user.expired) {
          setAuthState(AuthenticationState.LoggedIn);
        }
      } else {
        setAuthState(AuthenticationState.LoggedOut);
      }
    });
  }, [logOut, openIdUserManager]);

  // Dummy function only to support mobile app auth provider; this will not be used in web app auth provider; will be fixed in upcoming PRs
  const updateMobileAppAuthState = (
    authData: MobileAppAuthResponseType | undefined
  ) => {
    console.log('updateMobileAppAuthState', authData);
  };

  const value = {
    authState,
    getAuthUserInfo,
    logIn,
    logOut,
    updateMobileAppAuthState,
    handleWebAppLoginCallback,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
