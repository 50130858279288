import { LoadingSpinner } from '@3as-affiliates/shared/web/components';
import {
  AuthenticationState,
  useAuth,
} from '@3as-affiliates/shared/web/utils/auth-provider';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { defaultLoggedOutRoute } from '../routesConfig';

interface Props {
  Component: React.ElementType<unknown>;
}

export const AuthStateRoute: React.FC<Props> = ({ Component }) => {
  const { authState } = useAuth();

  return {
    [AuthenticationState.Uninitialized]: <LoadingSpinner />,
    [AuthenticationState.InProgress]: <LoadingSpinner />,
    [AuthenticationState.LoggedIn]: <Component />,
    [AuthenticationState.LoggedOut]: (
      <Navigate to={defaultLoggedOutRoute} replace={true} />
    ),
  }[authState];
};
